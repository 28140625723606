import React, { useEffect } from 'react';
import './App.css'

function App() {
  useEffect(() => {
    let keysPressed = [];
    const targetSequence = [37, 38, 39];

    const handleKeyDown = (event) => {
      keysPressed.push(event.keyCode);
      if (keysPressed.length > 3) {
        keysPressed.shift(); // Remove the oldest entry
      }

      if (keysPressed.toString() === targetSequence.toString()) {
        window.location.href = "https://youtu.be/LxxL8Mjy4yY?si=9yBCuxsMoeHOgaQQ";
      }
    };
    
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Corrected the placement of the dependency array

  return (
    <div className="main">
      <h1>Fuck Bassnectar, ya hear</h1>
    </div>
  );
}

export default App;
